import React from 'react';
import styled from 'styled-components';

const StyledSelect = styled.select`
    height: 40px;
    line-height: 24px;
    padding-left: 8px;
    border-radius: 8px;
    border: 1px solid var(--input-border-color);
    background-color: white;
    font-size: 16px;
    outline: 0;
    transition: all 300ms ease;
    flex-grow: 1;
    -webkit-appearance: none;

    &:focus {
        border: 1px solid var(--color-bimmer-blue);
    }
`;

const Select = (props) => <StyledSelect {...props} />;

export { Select };
