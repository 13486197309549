import React from 'react';
import { withRouter } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { State as FirebaseState } from '~/core/firebase';
import * as Items from './items';

const Navigation = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 101;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: space-between;
    justify-content: space-between;
    height: 100%;
    width: var(--navigation-width);
    border-right: 1px solid var(--color-grey);
`;

const Top = styled.div``;

const Bottom = styled.div``;

const NavItems = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 18px;
`;

const Component = ({ location }) => {
    const initialLoaded = useRecoilValue(FirebaseState.initialLoadState);
    const loggedIn = useRecoilValue(FirebaseState.loggedInState);

    return initialLoaded && loggedIn ? (
        <React.Fragment>
            <Navigation>
                <Top>
                    <Items.Banner />
                    <NavItems>
                        <Items.NavigationItem
                            text="Dashboard"
                            route="/dashboard"
                            icon="chart-line"
                            highlight={location.pathname === '/dashboard'}
                        />
                        <Items.NavigationItem text="History" route="/history" icon="history" highlight={location.pathname === '/history'} />
                        <Items.NavigationItem
                            text="Settings"
                            route="/settings"
                            icon="wrench"
                            highlight={location.pathname === '/settings'}
                        />
                    </NavItems>
                </Top>
                <Bottom>
                    <Items.UserAccount image={null} name="Urban Modeller" />
                </Bottom>
            </Navigation>
        </React.Fragment>
    ) : (
        <React.Fragment />
    );
};

const Side = withRouter(Component);

export { Side };
