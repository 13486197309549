import React from 'react';
import styled from 'styled-components';
import { Toasts } from '~/core/ui';
import { Logic } from '~/core/payments_stripe';

const Container = styled.div`
    position: fixed;
    top: var(--navigation-height);
    left: var(--navigation-width);
    z-index: 101;
    display: flex;
    background-color: var(--color-bimmer-blue);
    align-items: center;
    justify-content: center;
    width: calc(100% - var(--navigation-width));
    min-width: calc(var(--page-min-width) + var(--navigation-width));
    height: 36px;
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.05);
`;

const Text = styled.p`
    color: white;
    font-size: 14px;
`;

const Link = styled.span`
    color: white;
    font-weight: bold;
    cursor: pointer;
`;

const Message = ({ toast, children, showBillingButton }) => {
    const getURL = async () => {
        toast.add('Fetching billing account...');
        const url = await Logic.getBillingSession();
        if (!url) {
            toast.add('Unable to fetch billing account, please try again later.');
            return;
        }
        window.open(url, '_self');
    };

    return (
        <Container>
            <Text>
                {children}
                {showBillingButton ? <Link onClick={(event) => getURL()}>Manage Billing Account</Link> : <span />}
            </Text>
        </Container>
    );
};

const Banner = Toasts.withToasts(Message);
export { Banner };
