import React from 'react';
import styled from 'styled-components';

const Space = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
`;

const Left = ({ children }) => <Space>{children}</Space>;

export { Left };
