import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: -8px;
`;

const Icon = styled(FontAwesomeIcon)`
    margin: 0 8px;
`;

class Container extends React.PureComponent {
    processBlocks = () => {
        let blocks = Array.isArray(this.props.children) ? [this.props.children[0]] : [this.props.children];
        for (var i = 1; i < this.props.children.length; i++) {
            blocks.push(<Icon key={`crumbs_${i}`} fixedWidth icon={['fas', 'chevron-right']} color="var(--color-bimmer-blue)" />);
            blocks.push(this.props.children[i]);
        }
        return blocks;
    };

    render = () => {
        this.processBlocks();
        return <Wrapper>{this.processBlocks()}</Wrapper>;
    };
}

export { Container };
