import { Logic } from '~/core/firebase';
import { PAYMENTS_URL } from '~/core/constants';
import axios from 'axios';

const getBillingSession = async () => {
    return await Logic.fireauth.currentUser
        .getIdToken()
        .then(async (token) => {
            return await axios
                .get(`${PAYMENTS_URL}/v1/billing/portal_session`, {
                    headers: {
                        authorization: token,
                    },
                })
                .then((response) => response.data.url);
        })
        .catch((error) => {
            console.error(error);
            return undefined;
        });
};

export { getBillingSession };
