import React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { BrowserView, MobileView } from 'react-device-detect';

import { Routes } from '~/modules';
import { Logic as Firebase } from '~/core/firebase';
import { Mobile } from '~/core/mobile';
import { Core as PaymentsCore } from '~/core/payments_stripe';
import { Helmet } from '~/core/seo_helmet';
import { GlobalStyle, Toasts } from '~/core/ui';

const stripe = loadStripe(PaymentsCore.Constants.STRIPE_APIKEY);
const Application = () => (
    <RecoilRoot>
        <Firebase.FirebaseListener />
        <HelmetProvider>
            <Toasts.ToastProvider>
                <Elements stripe={stripe}>
                    <BrowserRouter>
                        <Helmet.CreateGlobal />
                        <GlobalStyle />
                        <BrowserView>
                            <Routes />
                        </BrowserView>
                        <MobileView>
                            <Mobile />
                        </MobileView>
                    </BrowserRouter>
                </Elements>
            </Toasts.ToastProvider>
        </HelmetProvider>
    </RecoilRoot>
);

ReactDOM.render(<Application />, document.getElementById('root'));
