import { fireauth, firebase } from '~/core/firebase/logic';

/**
 * **emailLogin**: Login to an existing account using an email/password combination
 *
 * @param {String} username Email
 * @param {String} password Password
 * @returns {Promise<{status: Boolean, error: String}>} `status` true on success, false on error. `error` contains error message on error, null on successful login
 */
const emailLogin = async (username, password) => {
    return await fireauth
        .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
        .then(async () => {
            return await fireauth
                .signInWithEmailAndPassword(username, password)
                .then(() => {
                    return {
                        status: true,
                        error: null,
                    };
                })
                .catch((error) => {
                    return _returnError(error.message);
                });
        })
        .catch((error) => {
            return _returnError(error.message);
        });
};

/**
 * **_returnError**: Simple helper to return the error
 *
 * @param {String} message Error message to return
 * @returns {{status: false, error: String}} `status` is always false, message parameter returned in `error`
 */
const _returnError = (message) => {
    return {
        status: false,
        error: message,
    };
};

export { emailLogin };
