import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    margin: 0;
    display: flex;
    flex-direction: row;
`;

const Footer = ({ children }) => <Container>{children}</Container>;

export { Footer };
