import { fireauth } from '~/core/firebase/logic';

/**
 * **resetPassword**: Reset a user's password
 *
 * @param {String} email User's email
 * @returns {Promise<{status: boolean, error: Error}>} Return struct
 */
export const resetPassword = async (email) => {
    return await fireauth
        .sendPasswordResetEmail(email)
        .then(() => {
            return {
                status: true,
                error: null,
            };
        })
        .catch((error) => {
            return {
                status: false,
                error: error.message,
            };
        });
};
