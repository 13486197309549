import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faChartLine,
    faChevronRight,
    faHistory,
    faRedo,
    faFileDownload,
    faMapMarked,
    faSave,
    faWrench,
    faHandshakeAlt,
    faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons';

library.add([
    //Solid
    faChartLine,
    faChevronRight,
    faQuestionCircle,
    faRedo,
    faFileDownload,
    faMapMarked,
    faSave,
    faHandshakeAlt,
    faWrench,
    faHistory,
]);
