import React from 'react';
import { Helmet } from 'react-helmet-async';
import propTypes from 'prop-types';

const CreateGlobal = () => (
    <Helmet>
        <title>Urban Modeller</title>
    </Helmet>
);

const Create = ({ title }) => (
    <Helmet>
        <title>Urban Modeller&ensp;&ndash;&ensp;{title}</title>
    </Helmet>
);

Create.propTypes = {
    title: propTypes.string.isRequired,
};

export { CreateGlobal, Create };
