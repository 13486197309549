import React from 'react';
import ReactLoading from 'react-loading';
import styled from 'styled-components';

const PageLoadingContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const PageLoading = () => (
    <PageLoadingContainer>
        <ReactLoading type="spin" color="rgba(87, 96, 219, 0.70)" />
    </PageLoadingContainer>
);

const StandardLoading = () => <ReactLoading type="spin" color="rgba(87, 96, 219, 0.70)" />;

const ErrorLoading = () => <div>Error</div>;

export { PageLoading, StandardLoading, ErrorLoading };
