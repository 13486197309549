import { Logic } from '~/core/firebase';

/**
 * **get**: Get a document's data, returned as object
 *
 * @param {String} path Path within Firestore
 * @returns {Object} Object containing document data
 */
const get = async (path) => {
    return Logic.firestore
        .doc(`/users/${Logic.fireauth.currentUser.uid}/bimmer/${path}`)
        .get()
        .then((document) => (document.data() ? document.data() : {}))
        .catch((error) => {
            console.error(error);
            return undefined;
        });
};

export { get };
