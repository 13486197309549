import React from 'react';
import styled from 'styled-components';
import Logo from '~/core/ui/assets/logo.svg';
import { Link } from 'react-router-dom';

const Container = styled.div`
    display: flex;
    width: var(--navigation-width);
    height: var(--navigation-height);
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid var(--color-grey);
`;

const Image = styled.img`
    width: 145px;
`;

const Banner = () => (
    <Link to="/dashboard">
        <Container>
            <Image src={Logo} alt="logo" />
        </Container>
    </Link>
);

export { Banner };
