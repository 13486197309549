import React from 'react';
import styled from 'styled-components';

const BlueButton = styled.button`
    --height: 40px;
    -webkit-appearance: none;
    outline: 0;
    border: 0;
    background-color: rgba(66, 163, 232, 1);
    background-image: linear-gradient(90deg, rgba(87, 96, 219, 1), rgba(66, 163, 232, 0));
    box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.06);
    color: white;
    font-weight: 700;
    height: var(--height);
    border-radius: var(--height);
    font-size: 14px;
    width: ${(props) => (props.width ? props.width : 'initial')};
    transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
    cursor: pointer;
    padding: 0 20px;

    &:hover {
        background-color: var(--color-bimmer-blue);
        box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.12);
    }
`;

const Button1 = (props) => <BlueButton {...props} />;

export { Button1 };
