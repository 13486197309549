import { Logic } from '~/core/firebase';

/**
 * **write**: Write to a document (at the given path) with the given object.
 *
 * @param {String} path Path within Firestore
 * @param {Object} data Data to write to object
 * @param {Boolean} merge Should merge the current data?
 */
const write = async (path, data, merge) =>
    Logic.firestore.doc(`/users/${Logic.fireauth.currentUser.uid}/bimmer/${path}`).set(data, { merge: merge });

export { write };
