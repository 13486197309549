import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/firestore';

const config = {
    apiKey: 'AIzaSyAX05Hpi57q_sfiQIL7nA_YTDY6MbQVdXk',
    authDomain: 'urban-modeller.firebaseapp.com',
    databaseURL: 'https://urban-modeller-default-rtdb.firebaseio.com',
    projectId: 'urban-modeller',
    storageBucket: 'urban-modeller.appspot.com',
    messagingSenderId: '1071095254000',
    appId: '1:1071095254000:web:702098a07d8ff940fbab35',
};

firebase.initializeApp(config);

const fireauth = firebase.auth();
const firestorage = firebase.storage();
const firestore = firebase.firestore();

export { firebase, fireauth, firestorage, firestore };
