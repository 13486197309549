import React from 'react';
import styled, { css } from 'styled-components';

const Shared = css`
    display: flex;
    box-sizing: border-box;
    transition: all 300ms ease;
    background-color: white;
    border-radius: 15px;
    border: var(--navigation-border-size) solid rgba(225, 225, 225, 0.666);
    width: ${(props) => (props.width ? props.width : '100%')};
    min-width: ${(props) => (props.minWidth ? props.minWidth : 'initial')};
    max-width: ${(props) => (props.maxWidth ? props.maxWidth : 'initial')};
    &:hover {
        box-shadow: 0 10px 40px rgba(0, 0, 0, 0.05);
    }
`;

const ScrollableRow = styled.div`
    ${Shared}
    height: calc(100% - 32px);
    overflow-y: auto;
`;

const Row = styled.div`
    ${Shared}
    overflow-y: auto;
`;

const ScrollableContainer = ({ children }) => <ScrollableRow>{children}</ScrollableRow>;
const Container = ({ children, width, minWidth, maxWidth }) => (
    <Row width={width} minWidth={minWidth} maxWidth={maxWidth}>
        {children}
    </Row>
);

export { ScrollableContainer, Container };
