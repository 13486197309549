import React from 'react';
import styled from 'styled-components';

const Content = styled.a`
    color: ${(props) => (props.color ? props.color : 'var(--color-bimmer-blue)')};
    font-weight: 500;
    text-decoration: none;
`;

const Link = ({ children, color, link }) => (
    <Content color={color} href={link} target="_blank">
        {children}
    </Content>
);

export { Link };
