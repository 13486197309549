import React from 'react';
import styled from 'styled-components';

const Label = styled.label`
    margin-top: 8px;
    font-size: 13px;
    color: var(--color-darkgrey);
    cursor: pointer;
`;

const Input = styled.input`
    font-size: 12px;
`;

const Radio = (props) => {
    const { children, ...other } = props;
    return (
        <Label>
            <Input type="radio" {...other} />
            &emsp;&emsp;&emsp;&emsp;
            {children}
        </Label>
    );
};

export { Radio };
