import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import DefaultImage from '~/core/ui/assets/default_user.png';

const Container = styled.div`
    font-weight: 600;
    font-size: 14px;
    display: flex;
    height: var(--navigation-height);
    align-items: center;
    padding: 0 3px 0 18px;
    border-left: 1px solid var(--color-grey);
`;

const Image = styled.img`
    height: calc(var(--navigation-height) - 30px);
    width: auto;
    border-radius: 50%;
    margin-left: 18px;
`;

const UserProfile = ({ name, image }) => (
    <Container>
        {name}
        <Image src={image === null ? DefaultImage : image} alt="Profile Picture" />
    </Container>
);

UserProfile.propTypes = {
    name: propTypes.string,
    image: propTypes.string,
};

UserProfile.defaultProps = {
    name: 'Unknown User',
    image: DefaultImage,
};

export { UserProfile };
