import * as Constants from '~/core/constants';

import { Logic } from '~/core/firebase';
import axios from 'axios';

/**
 *
 * @param {String} paymentMethodID Payment method ID from Stripe
 * @param {Stripe} stripe Stripe object
 * @returns {Boolean} True on a successful subscription, error on any problems
 */
const createSubscription = async (paymentMethodID, stripe) => {
    return await Logic.fireauth.currentUser.getIdToken().then(async (token) => {
        return await axios
            .post(
                `${Constants.PAYMENTS_URL}/v1/billing/create_subscription`,
                {
                    payment_method_id: paymentMethodID,
                },
                {
                    headers: {
                        authorization: token,
                    },
                },
            )
            .then((response) => {
                if (response.data.error) throw response.data;
                return {
                    subscription: response.data,
                    paymentMethodID: paymentMethodID,
                    priceID: Constants.PRO_PRICE_ID,
                    stripe: stripe,
                };
            })
            .then(handlePaymentRequiresAction)
            .then(handleRequiresPaymentMethod)
            .then(subscriptionComplete);
    });
};

/**
 *
 * @param {String} paymentMethodID Payment method ID from Stripe
 * @param {String} invoiceID Invoice ID (typically pulled from localStorage)
 * @param {Stripe} stripe stripe object
 * @returns {Boolean} True on a successful subscription, error on any problems
 */
const retryInvoice = async (paymentMethodID, invoiceID, stripe) => {
    return await Logic.fireauth.currentUser.getIdToken().then(async (token) => {
        return await axios
            .post(
                `${Constants.PAYMENTS_URL}/v1/billing/retry_invoice`,
                {
                    invoice_id: invoiceID,
                    payment_method_id: paymentMethodID,
                },
                {
                    headers: {
                        authorization: token,
                    },
                },
            )
            .then((response) => {
                if (response.data.error) throw response.data;
                return {
                    invoice: response.data,
                    paymentMethodID: paymentMethodID,
                    priceID: Constants.PRO_PRICE_ID,
                    isRetry: true,
                    stripe: stripe,
                };
            })
            .then(handlePaymentRequiresAction)
            .then(subscriptionComplete);
    });
};

const handlePaymentRequiresAction = ({ subscription, invoice, priceID, paymentMethodID, isRetry, stripe }) => {
    if (subscription && subscription.status === 'active') return { subscription, paymentMethodID, priceID };
    let paymentIntent = invoice ? invoice.payment_intent : subscription.latest_invoice.payment_intent;
    if (paymentIntent.status === 'requires_action' || (isRetry === true && paymentIntent.status === 'requires_payment_method')) {
        return stripe
            .confirmCardPayment(paymentIntent.client_secret, {
                payment_method: paymentMethodID,
            })
            .then((result) => {
                if (result.error) throw result;
                if (result.paymentIntent.status === 'succeeded') {
                    return {
                        priceID: priceID,
                        subscription: subscription,
                        invoice: invoice,
                        paymentMethodID: paymentMethodID,
                    };
                }
                throw Error('Card verification action was not completed successfully.');
            });
    }
    return { subscription, priceID, paymentMethodID };
};

const handleRequiresPaymentMethod = ({ subscription, paymentMethodID, priceID }) => {
    if (subscription && subscription.status === 'active') return { subscription, paymentMethodID, priceID };
    if (subscription && subscription.latest_invoice && subscription.latest_invoice.payment_intent.status === 'requires_payment_method') {
        localStorage.setItem('latestInvoiceId', subscription.latest_invoice.id);
        localStorage.setItem('latestInvoicePaymentIntentStatus', subscription.latest_invoice.payment_intent.status);
        throw new Error('Your card was declined.');
    }
    return { subscription, paymentMethodID, priceID };
};

const subscriptionComplete = async (result) => {
    if (result && !result.subscription) {
        const subscription = { id: result.invoice.subscription };
        result.subscription = subscription;
        localStorage.clear();
    }
    return true;
};

export { createSubscription, retryInvoice };
