import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    margin-top: calc(var(--navigation-sticky-height) + 17px);
    padding: 1px 24px 17px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    width: 100%;
    box-sizing: border-box;
    height: calc(100% - var(--navigation-sticky-height) - 18px);
    min-width: calc(var(--page-min-width) + var(--navigation-width));
    z-index: 0;
`;

const Body = ({ children }) => (
    <Container>
        <div>{children}</div>
    </Container>
);

export { Body };
