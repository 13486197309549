import React from 'react';
import styled from 'styled-components';

const StyledInput = styled.input`
    height: 40px;
    line-height: 24px;
    padding: 0 0 0 8px;
    border-radius: 8px;
    border: 1px solid var(--input-border-color);
    background-color: white;
    font-size: 16px;
    width: ${(props) => (props.width ? props.width : 'initial')};
    outline: 0;
    transition: all 300ms ease;
    flex-grow: 1;

    &:focus {
        border: 1px solid var(--color-bimmer-blue);
    }

    &:disabled {
        border: none;
        background-color: var(--color-grey);
    }

    &[type='number']::-webkit-inner-spin-button,
    &[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`;

const Input = (props) => <StyledInput {...props} />;

export { Input };
