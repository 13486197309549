// URLs
const API_URL = 'https://urbanmodeller.site';
const PAYMENTS_URL = 'https://urbanmodeller.site';

// System
const MAPBOX_APIKEY = 'pk.eyJ1IjoiamFnYW5kZWVwYnJhciIsImEiOiJjanpianNwaGIwMDB1M25vMDF6MWV3b282In0.2OCy9wF-ZeLALxxgUa443g';
const PRO_PRICE_ID = 'price_1KzThQKtG09FkitfRqMuCwa3';

// Model Data
const MODEL_TYPES = {
    SKETCHUP: 'Sketchup',
    DXF: 'AutoCAD DXF',
};
const MODEL_RADIUS_MIN = 50;
const MODEL_RADIUS_MAX = 1500;

// Company Data
const COMPANY_TYPES = {
    DEVELOPER_OWNER: 'Developer/Owner',
    ARCHITECT_ENGINEER: 'Architect/Engineer',
    OEM: 'OEM',
    GOVERNMENT_UTILITY: 'Government/Utility',
    OTHER: 'Other',
};
const COMPANY_SIZES = {
    ONE_TO_TEN: '1-10 Employees',
    ELEVEN_TO_FIFTY: '11-50 Employees',
    FIFTYONE_TO_ONEHUNDRED: '51-100 Employees',
    MORE_THAN_ONEHUNDRED: '100+ Employees',
};

// SUBSCRIPTION
const SUBSCRIPTION_ACTIVE_STATUS = ['active', 'incomplete', 'past_due', 'unpaid', 'beta', 'employee'];
const SUBSCRIPTION_PROBLEM_STATUS = ['incomplete', 'past_due', 'unpaid'];

export {
    //URLs
    API_URL,
    PAYMENTS_URL,
    //System
    MAPBOX_APIKEY,
    PRO_PRICE_ID,
    //Model
    MODEL_TYPES,
    MODEL_RADIUS_MIN,
    MODEL_RADIUS_MAX,
    //Company
    COMPANY_TYPES,
    COMPANY_SIZES,
    //Subscription
    SUBSCRIPTION_ACTIVE_STATUS,
    SUBSCRIPTION_PROBLEM_STATUS,
};
