import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';

const Block = styled(Link)`
    text-decoration: none;
    font-size: 14px;
    padding: 0 8px;
    height: 24px;
    line-height: 24px;
    border-radius: 20px;
    transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);

    &:hover {
        background-color: #e1e1e1;
    }
`;

class Item extends React.PureComponent {
    render = () => {
        return <Block to={this.props.path}>{this.props.title}</Block>;
    };
}

Item.propTypes = {
    title: propTypes.string.isRequired,
    path: propTypes.string.isRequired,
};

Item.defaultProps = {
    title: 'Unknown Path',
    path: '/dashboard',
};

export { Item };
