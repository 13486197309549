import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const Icon = styled(FontAwesomeIcon)`
    color: var(--color-bimmer-blue);
    margin-right: 15px;
`;

const Text = styled.h1`
    font-size: ${(props) => (props.small ? '18px' : '28px')};
    line-height: ${(props) => (props.small ? '40px' : '30px')};
    margin: 8px 0;
`;

const Message = ({ text, icon, small }) => {
    return (
        <Container>
            <Text small={small}>
                {icon.length === 0 ? <span /> : <Icon icon={icon} />}
                {text}
            </Text>
        </Container>
    );
};

Message.propTypes = {
    text: propTypes.string.isRequired,
    icon: propTypes.array,
    small: propTypes.bool,
};

Message.defaultProps = {
    text: 'Unknown',
    icon: [],
    small: false,
};

export { Message };
