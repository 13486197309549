import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    position: fixed;
    top: var(--navigation-height);
    left: var(--navigation-width);
    padding-top: 17px;
    margin-left: 24px;
    width: calc(100% - var(--navigation-width) - 48px);
    min-width: calc(var(--page-min-width) + var(--navigation-width) - 48px);
    height: var(--navigation-sticky-height);
    border-bottom: var(--navigation-border-size) solid var(--color-grey);
    background-color: var(--color-background);
    z-index: 50;
`;

const Body = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    justify-content: space-between;
`;

const Workspace = ({ children }) => {
    return (
        <Container>
            <Body>{children}</Body>
        </Container>
    );
};

export { Workspace };
