import React from 'react';
import { Logic } from '~/core/firebase';
import { withRouter } from 'react-router-dom';
import { Async } from '~/core/ui';

const Route = (Component) => {
    return class extends React.PureComponent {
        constructor(props) {
            super();
            this.state = {
                checked: false,
            };
        }

        componentDidMount = () => this.check();

        check = () =>
            Logic.fireauth.onAuthStateChanged(async (user) => {
                !user ? this.setState({ checked: true }) : this.props.history.push('/dashboard');
            });

        render = () => (this.state.checked ? <Component {...this.props} /> : <Async.PageLoading />);
    };
};

const unprotectedRoute = (component) => withRouter(Route(component));
export { unprotectedRoute };
