import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    margin: 20px 0px;
`;

const Body = ({ children }) => <Container>{children}</Container>;

export { Body };
