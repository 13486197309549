import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    margin-top: ${(props) => (props.marginTop ? props.marginTop : '0')};
    margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '0')};
`;

const Content = styled.span`
    font-size: 14px;
    color: ${(props) => (props.color ? props.color : 'var(--color-darkgrey)')};
`;

const Text = ({ children, color, marginBottom, marginTop }) => (
    <Container marginBottom={marginBottom} marginTop={marginTop}>
        <Content color={color}>{children}</Content>
    </Container>
);

export { Text };
