import React from 'react';
import styled from 'styled-components';

const StyledLabel = styled.label`
    font-family: Montserrat, sans-serif;
    color: ${(props) => (props.sublabel ? '#aeaeae' : 'var(--color-darkergrey)')};
    font-weight: ${(props) => (props.sublabel ? '500' : 'bold')};
    font-size: ${(props) => (props.sublabel ? '12px' : '14px')};
    margin-bottom: ${(props) => (props.sublabel ? '8px' : '5px')};
`;

const Label = (props) => <StyledLabel {...props} />;

export { Label };
