import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    background-color: white;
    width: ${(props) => (props.width ? props.width : '100%')};
    padding: ${(props) => (props.padding ? props.padding : '16px')};
    box-sizing: border-box;
    cursor: ${(props) => (props.cursor ? props.cursor : 'default')};
`;

const Slab = ({ children, cursor, width, padding }) => (
    <Container cursor={cursor} width={width} padding={padding}>
        {children}
    </Container>
);

export { Slab };
