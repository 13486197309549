import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

const Text = styled.p`
    margin-left: 25px;
    font-weight: 700;
    font-size: 16px;
`;

const Location = ({ location }) => <Text>{location}</Text>;

Location.propTypes = {
    location: propTypes.string,
};

Location.defaultProps = {
    location: 'Context Model Generation',
};

export { Location };
