import styled from 'styled-components';

const Row = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`;

const FullRow = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const FullColumn = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

const Description = styled.div`
    display: flex;
    flex-direction: column;
    width: 325px;
    margin-right: 20px;
`;

const HorizontalSpacer = styled.div`
    width: ${(props) => (props.width ? props.width : '0px')};
`;

const VerticalSpacer = styled.div`
    height: ${(props) => (props.height ? props.height : '0px')};
`;

export { Row, FullRow, FullColumn, Column, Description, HorizontalSpacer, VerticalSpacer };
