import React from 'react';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';

const { Consumer, Provider } = React.createContext();

const Message = styled.div`
    background: var(--gradient-bimmer-blue);
    border-radius: 25px;
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.05);
    color: white;
    text-align: center;
    vertical-align: middle;
    font-size: 14px;
    font-weight: 500;
    line-height: 47px;
    margin: 25px;
    padding: 0px 25px;
    z-index: 101010;
`;

const Container = styled.div`
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 101010;
`;

class Toast extends React.Component {
    constructor() {
        super();
        this.state = {
            visible: false,
        };
    }

    componentDidMount = () => {
        this.setTimer();
    };

    componentWillUnmount = () => {
        clearTimeout(this._timer);
    };

    setTimer = () => {
        if (this._timer != null) {
            clearTimeout(this._timer);
        }
        if (this.props.timeout)
            this._timer = setTimeout(() => {
                this.setState({
                    visible: false,
                });
                this._timer = null;
            }, 2500);
        this.setState({
            visible: true,
        });
    };

    render = () => {
        return (
            <CSSTransition in={this.state.visible} timeout={200} classNames="fadeAnimation" onExited={() => this.props.timeout()}>
                <Message>{this.props.children}</Message>
            </CSSTransition>
        );
    };
}

class ToastProvider extends React.Component {
    count = 0;
    state = { toasts: [] };

    add = (content, timeout = true) => {
        const id = this.count++;
        this.setState((state) => {
            const toasts = state.toasts.slice(0);
            toasts.push({ content, id, timeout });
            return { toasts };
        });
        return id;
    };

    remove = (id) => {
        this.setState((state) => {
            const toasts = state.toasts.filter((toast) => toast.id !== id);
            return { toasts };
        });
        return id;
    };

    timeout = (id) => () => this.remove(id);

    render() {
        const context = {
            add: this.add,
            remove: this.remove,
        };

        return (
            <Provider value={context}>
                {this.props.children}
                <Container>
                    {this.state.toasts.map(({ content, id, timeout, ...rest }) => (
                        <Toast key={id} timeout={timeout ? this.timeout(id) : null} {...rest}>
                            {content}
                        </Toast>
                    ))}
                </Container>
            </Provider>
        );
    }
}

const withToasts = (Component) => (props) => <Consumer>{(context) => <Component toast={context} {...props} />}</Consumer>;

export { ToastProvider, withToasts };
