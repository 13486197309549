import React from 'react';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { State as FirebaseState } from '~/core/firebase';
import { Banner } from '~/core/ui';
import * as Items from '~/core/ui/navigation/top/items';
import { SUBSCRIPTION_PROBLEM_STATUS } from '~/core/constants';

const Navigation = styled.div`
    position: fixed;
    top: 0;
    left: var(--navigation-width);
    z-index: 100;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: calc(100% - var(--navigation-width));
    min-width: calc(var(--page-min-width) + var(--navigation-width));
    height: var(--navigation-height);
    border-bottom: 1px solid var(--color-grey);
`;

const Top = () => {
    const initialLoaded = useRecoilValue(FirebaseState.initialLoadState);
    const loggedIn = useRecoilValue(FirebaseState.loggedInState);
    const subscriptionDocument = useRecoilValue(FirebaseState.subscriptionDocumentState);

    const showBanner = () => (subscriptionDocument ? SUBSCRIPTION_PROBLEM_STATUS.includes(subscriptionDocument.status) : false);

    return initialLoaded && loggedIn ? (
        <React.Fragment>
            <Navigation>
                <Items.Location />
            </Navigation>
            {showBanner() ? <Banner showBillingButton>There is a problem with your subscription:&ensp;</Banner> : <React.Fragment />}
        </React.Fragment>
    ) : (
        <React.Fragment />
    );
};

export { Top };
