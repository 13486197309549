import React from 'react';
import { useRecoilValue } from 'recoil';
import { State as FirebaseState } from '~/core/firebase';
import { Navigation, Page } from '~/core/ui';

const NavigationHOC = ({ children }) => {
    const initialLoaded = useRecoilValue(FirebaseState.initialLoadState);
    const loggedIn = useRecoilValue(FirebaseState.loggedInState);

    return initialLoaded ? (
        loggedIn ? (
            <React.Fragment>
                <Navigation.Side />
                <Navigation.Top />
                <Page side={true} top={true}>
                    {children}
                </Page>
            </React.Fragment>
        ) : (
            <React.Fragment>
                <Page side={false} top={false}>
                    {children}
                </Page>
            </React.Fragment>
        )
    ) : (
        <React.Fragment />
    );
};

export { NavigationHOC };
