import React from 'react';
import styled from 'styled-components';

const StyledText = styled.p`
    margin: 0;
    padding: 0;
    color: var(--color-darkergrey);
`;

const Text = ({ children }) => <StyledText>{children}</StyledText>;

export { Text };
