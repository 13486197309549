import { atom } from 'recoil';

const initialLoadState = atom({
    key: 'firebase_initialLoadState',
    default: false,
});

const loggedInState = atom({
    key: 'firebase_loggedInState',
    default: false,
});

const nameState = atom({
    key: 'firebase_nameState',
    default: '',
});

const imageState = atom({
    key: 'firebase_imageState',
    default: null,
});

const verifiedState = atom({
    key: 'firebase_verifiedState',
    default: false,
});

const subscriptionDocumentState = atom({
    key: 'firebase_subscriptionDocumentState',
    default: undefined,
});

export { initialLoadState, loggedInState, nameState, imageState, verifiedState, subscriptionDocumentState };
