import styled from 'styled-components';

const Page = styled.div`
    position: fixed;
    overflow-y: auto;
    top: ${(props) => (props.top ? 'var(--navigation-height)' : '0')};
    left: ${(props) => (props.side ? 'var(--navigation-width)' : '0')};
    box-sizing: border-box;
    height: ${(props) => (props.top ? 'calc(100% - var(--navigation-height))' : '100%')};
    width: ${(props) => (props.side ? 'calc(100% - var(--navigation-width))' : '100%')};
    min-width: var(--page-min-width);
    min-height: 500px;
    display: flex;
    flex-direction: column;
`;

export { Page };
