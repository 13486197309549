import React from 'react';
import styled from 'styled-components';

const Button = styled.button`
    --height: 40px;
    -webkit-appearance: none;
    outline: 0;
    border: 0;
    background-color: white;
    box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.06);
    font-weight: 700;
    height: var(--height);
    width: ${(props) => (props.width ? props.width : 'initial')};
    border-radius: var(--height);
    font-size: 14px;
    transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
    cursor: pointer;
    padding: 0 20px;

    &:hover {
        box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.12);
    }
`;

const Button2 = (props) => <Button type="button" {...props} />;

export { Button2 };
