import { createGlobalStyle, css } from 'styled-components';
import { normalize } from 'styled-normalize';

const ToastAnimation = css`
    .fadeAnimation-enter {
        opacity: 0;
    }
    .fadeAnimation-enter-active {
        opacity: 1;
        transition: opacity 200ms;
    }
    .fadeAnimation-exit {
        opacity: 1;
    }
    .fadeAnimation-exit-active {
        opacity: 0;
        transition: opacity 200ms;
    }
`;

const Constants = css`
    :root {
        /* Size */
        --page-min-width: 720px;

        /* Navigation */
        --navigation-height: 56px;
        --navigation-width: 224px;
        --navigation-border-size: 1px;
        --navigation-sticky-height: 88px;

        /* UI */
        --input-border-color: #aeaeae;

        /* Colors */
        --color-background: #f8faff;
        --color-bimmer-blue: #5760db;
        --color-bimmer-teal: #03cadf;

        /* Colors */
        --color-lightblue: #5672aa;
        --color-blue: #3c5077;
        --color-lightgrey: #f9f9f9;
        --color-grey: #e1e1e1;
        --color-greyer: #a6a6a6;
        --color-darkgrey: #4f4e50;
        --color-darkergrey: #333333;

        /* Gradients */
        --gradient-bimmer-blue: linear-gradient(90deg, rgba(87, 96, 219, 1) 0%, rgba(66, 163, 232, 1) 100%);
        --gradient-bimmer-teal: linear-gradient(90deg, rgba(3, 202, 223, 1) 0%, rgba(66, 251, 197, 1) 100%);
    }

    ${ToastAnimation}
`;

const GlobalStyle = createGlobalStyle`
    ${normalize}
    ${Constants}

    html * {
        font-family: 'Product Sans', sans-serif;
    }

    p, input, label, span, table, a, h1, h2, h3, h4, button {
        color: var(--color-darkergrey);
    }

    html, body {
        background-color: var(--color-background);
        height: 100%;
        width: 100%;
    }
`;

export { GlobalStyle };
