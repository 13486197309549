import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import { withRouter, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StyledLink = styled(Link)`
    text-decoration: none;

    width: var(--navigation-width);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 18px;
    color: ${(props) => (props.highlight ? 'var(--color-bimmer-blue)' : 'black')};
    transition: all 100ms ease-in-out;

    &:hover {
        color: ${(props) => (props.highlight ? 'var(--color-bimmer-blue)' : 'rgba(66, 163, 232, 1)')};
    }
`;

const StyledIcon = styled(FontAwesomeIcon)`
    margin: 0 19px;
`;

const Text = styled.p`
    font-weight: ${(props) => (props.highlight ? 500 : 400)};
    font-size: 14px;
    margin: 0;
    color: inherit;
`;

const Component = ({ icon, text, route, highlight }) => {
    return (
        <React.Fragment>
            <StyledLink to={route} highlight={highlight ? 'true' : undefined}>
                <StyledIcon fixedWidth icon={['fas', icon]} highlight={highlight ? 'true' : undefined} />
                <Text highlight={highlight ? 'true' : undefined}>{text}</Text>
            </StyledLink>
        </React.Fragment>
    );
};

const NavigationItem = withRouter(Component);

NavigationItem.propTypes = {
    icon: propTypes.string.isRequired,
    text: propTypes.string.isRequired,
    route: propTypes.string.isRequired,
};

NavigationItem.defaultProps = {
    icon: 'question-mark',
    text: 'Unknown',
    route: '/',
};

export { NavigationItem };
