import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    height: 34px;
    min-height: 34px;
    max-height: 34px;
    margin-top: ${(props) => (props.marginTop ? props.marginTop : '7px')};
    flex-grow: 1;
    margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '0')};
`;

const Title = styled.span`
    font-size: 18px;
    font-weight: bold;
    color: ${(props) => (props.color ? props.color : 'var(--color-darkergrey)')};
`;

const Subheader = ({ children, color, marginBottom, marginTop }) => (
    <Container marginBottom={marginBottom} marginTop={marginTop}>
        <Title color={color}>{children}</Title>
    </Container>
);

export { Subheader };
