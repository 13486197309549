import React from 'react';
import { Logic } from '~/core/firebase';
import { withRouter } from 'react-router-dom';
import { Logic as Database } from '~/core/firebase_firestore';
import { Async } from '~/core/ui';

const Route = (Component) => {
    return class extends React.PureComponent {
        constructor(props) {
            super();
            this.state = {
                checked: false,
                need_onboard: false,
            };
        }

        componentDidMount = () => {
            Logic.fireauth.onAuthStateChanged(async (user) => {
                if (process.env.NODE_ENV === 'development' && user) console.log(await user.getIdToken());
                user ? this.loggedIn() : this.loggedOut();
            });
        };

        loggedIn = async () => {
            if (await this.checkHasOnboarded()) {
                this.inOnboardedRoute() ? this.props.history.push('/dashboard') : this.setState({ checked: true });
            } else {
                this.inOnboardedRoute()
                    ? this.setState({ checked: true })
                    : this.props.history.push(`/welcome?redirect=${this.props.location.pathname}${this.props.location.search}`);
            }
        };

        loggedOut = () => {
            this.props.history.push(`/login?redirect=${this.props.location.pathname}${this.props.location.search}`);
        };

        inOnboardedRoute = () => this.props.location.pathname === '/welcome';

        checkHasOnboarded = async () => {
            const account = await Database.get('account');
            return account.onboarded ?? false;
        };

        render = () => (this.state.checked ? <Component {...this.props} /> : <Async.PageLoading />);
    };
};

const protectedRoute = (component) => withRouter(Route(component));
export { protectedRoute };
