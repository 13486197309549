import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import loadable from '@loadable/component';
import { Async } from '~/core/ui';
import { HOCs as Fireauth } from '~/core/firebase_auth';
import { NavigationHOC } from '~/core/ui/navigation';

const Login = loadable(() => import('~/modules/login/route'), {
    resolveComponent: (module) => module.Login,
    fallback: Async.PageLoading(),
});

const Register = loadable(() => import('~/modules/register/route'), {
    resolveComponent: (module) => module.Register,
    fallback: Async.PageLoading(),
});

const Dashboard = loadable(() => import('~/modules/dashboard/route'), {
    resolveComponent: (module) => module.Dashboard,
    fallback: Async.PageLoading(),
});

const History = loadable(() => import('~/modules/history/route'), {
    resolveComponent: (module) => module.History,
    fallback: Async.PageLoading(),
});

const Settings = loadable(() => import('~/modules/settings/route'), {
    resolveComponent: (module) => module.SettingsRoute,
    fallback: Async.PageLoading(),
});

const Welcome = loadable(() => import('~/modules/onboarding/route'), {
    resolveComponent: (module) => module.Welcome,
    fallback: Async.PageLoading(),
});

class Routes extends React.PureComponent {
    render = () => (
        <NavigationHOC>
            <Switch>
                <Route path="/dashboard" component={Fireauth.protectedRoute(Dashboard)} />
                <Route path="/settings" component={Fireauth.protectedRoute(Settings)} />
                <Route path="/welcome" component={Fireauth.protectedRoute(Welcome)} />
                <Route path="/history" component={Fireauth.protectedRoute(History)} />
                <Route path="/login" component={Fireauth.unprotectedRoute(Login)} />
                <Route path="/register" component={Fireauth.unprotectedRoute(Register)} />
                <Route render={() => <Redirect to="/dashboard" />} />
            </Switch>
        </NavigationHOC>
    );
}

export { Routes };
