import React from 'react';
import styled from 'styled-components';

const Text = styled.p`
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
    margin: 0;
    color: var(--color-darkergrey);
`;

const Header = ({ children }) => <Text>{children}</Text>;

export { Header };
