import { Logic, State } from '~/core/firebase';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';

import { API_URL } from '~/core/constants';
import React from 'react';
import axios from 'axios';

const FirebaseListener = () => {
    const subscriptionListener = React.useRef(undefined);
    // Setters
    const setInitialLoad = useSetRecoilState(State.initialLoadState);
    const setName = useSetRecoilState(State.nameState);
    const setImage = useSetRecoilState(State.imageState);
    const [loggedIn, setLoggedIn] = useRecoilState(State.loggedInState);
    const setSubscriptionDocument = useSetRecoilState(State.subscriptionDocumentState);
    const setVerified = useSetRecoilState(State.verifiedState);
    // Resetters
    const resetName = useResetRecoilState(State.nameState);
    const resetImage = useResetRecoilState(State.imageState);
    const resetLoggedIn = useResetRecoilState(State.loggedInState);
    const resetSubscriptionDocument = useResetRecoilState(State.subscriptionDocumentState);
    const resetVerified = useResetRecoilState(State.verifiedState);

    // Auth
    React.useEffect(() => {
        Logic.fireauth.onAuthStateChanged((user) => {
            if (user) {
                if (!loggedIn) logAnalytics(user);
                setName(user.displayName ?? user.email);
                setImage(user.photoURL);
                setVerified(user.emailVerified);
                subscriptionListener.current = Logic.firestore.doc(`users/${user.uid}/bimmer/subscription`).onSnapshot((doc) => {
                    if (doc.data()) setSubscriptionDocument(doc.data());
                });
                setLoggedIn(true);
            } else {
                resetLoggedIn();
                resetName();
                resetImage();
                resetSubscriptionDocument();
                resetVerified();
                if (subscriptionListener.current) subscriptionListener.current();
            }
            setInitialLoad(true);
        });
    }, [
        // Getter
        loggedIn,
        // Setters
        setLoggedIn,
        setImage,
        setName,
        setInitialLoad,
        setSubscriptionDocument,
        setVerified,
        // Resetters
        resetSubscriptionDocument,
        resetImage,
        resetLoggedIn,
        resetName,
        resetVerified,
    ]);

    /**
     * Log a login.
     *
     * @param {Logic.firebase.User} user Firebase user
     */
    const logAnalytics = async (user) => {
        if (process.env.NODE_ENV !== 'development') {
            const token = await user.getIdToken();
            await axios({
                method: 'POST',
                url: `${API_URL}/v1/analytics/signin`,
                data: {
                    uid: user.uid,
                    email: user.email,
                },
                headers: { authorization: token },
            }).catch((error) => {
                console.warn(error);
            });
        }
    };

    return null;
};

export { FirebaseListener };
