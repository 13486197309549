import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 0;
    left: 0;
    background-image: var(--gradient-bimmer-blue);
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
`;

const Message = styled.p`
    color: white;
    text-align: center;
    margin: 15px;
    font-size: 18px;
`;

const Mobile = () => (
    <Container>
        <Message>Urban Modeller does not currently support mobile browsers.</Message>
        <Message>Please access Urban Modeller from a desktop browser.</Message>
    </Container>
);

export { Mobile };
