import React from 'react';
import Tippy from '@tippyjs/react';
import styled, { css } from 'styled-components';
import { useRecoilValue } from 'recoil';
import { State as FirebaseState } from '~/core/firebase';
import { Button1 } from '~/core/ui/button';
import { fireauth } from '~/core/firebase/logic';
import DefaultImage from '~/core/ui/assets/default_user.png';
import { SUBSCRIPTION_ACTIVE_STATUS } from '~/core/constants';
import 'tippy.js/dist/tippy.css';
import './user.style.css';

const TippyBlock = styled.div`
    &:not(:first-child) {
        border-top: 1px solid rgba(225, 225, 225, 0.333);
    }
    color: white;
    padding: 15px 0;
    width: 100%;
`;

const TippyContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0 15px;
    min-width: calc(var(--navigation-width) - 80px);
`;

const Item = css`
    color: white;
    transition: all 100ms ease-in-out;
    margin: 0;
    box-sizing: border-box;
    font-weight: 500;
    width: 100%;
`;

const Information = styled.p`
    ${Item}
`;

const Action = styled.p`
    ${Item}
    cursor: pointer;

    &:hover {
        color: rgba(66, 163, 232, 1);
    }
`;

const Highlight = styled.span`
    color: rgba(66, 163, 232, 1);
`;

const Container = styled.div`
    display: flex;
    width: var(--navigation-width);
    height: var(--navigation-height);
    align-items: center;
    justify-content: center;
`;

const InnerContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const StyledButton = styled(Button1)`
    padding: 0 10px 0 20px;
    margin-bottom: 15px;
    width: calc(100% - 30px);
`;

const Image = styled.img`
    height: calc(var(--navigation-height) - 30px);
    width: auto;
    border-radius: 50%;
    margin-left: 18px;
`;

const Text = styled.span`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: white;
    text-align: left;
    flex-grow: 1;
    font-weight: 500;
`;

const UserAccount = () => {
    const [tooltip, setTooltip] = React.useState(false);
    const name = useRecoilValue(FirebaseState.nameState);
    const image = useRecoilValue(FirebaseState.imageState);

    const onClick = () => setTooltip(!tooltip);

    return (
        <Tippy content={<TippyContent />} visible={tooltip} arrow={true} interactive={true} onClickOutside={() => setTooltip(false)}>
            <Container>
                <StyledButton onClick={() => onClick()}>
                    <InnerContainer>
                        <Text>{name}</Text>
                        <Image src={image === null ? DefaultImage : image} alt="Profile Picture" />
                    </InnerContainer>
                </StyledButton>
            </Container>
        </Tippy>
    );
};

const TippyContent = () => {
    const signOut = () => fireauth.signOut();

    const subscriptionDocument = useRecoilValue(FirebaseState.subscriptionDocumentState);

    return (
        <TippyContainer>
            <TippyBlock>
                <Action onClick={() => signOut()}>Sign Out</Action>
            </TippyBlock>
            {subscriptionDocument !== undefined && subscriptionDocument !== null ? (
                SUBSCRIPTION_ACTIVE_STATUS.includes(subscriptionDocument.status) ? (
                    <React.Fragment />
                ) : (
                    <TippyBlock>
                        <Information>
                            <Highlight>
                                {subscriptionDocument.tokens ?? '?'} Remaining {subscriptionDocument.tokens === 1 ? 'Token' : 'Tokens'}
                            </Highlight>
                        </Information>
                    </TippyBlock>
                )
            ) : (
                <React.Fragment />
            )}
        </TippyContainer>
    );
};

export { UserAccount };
